<template>
  <div class="container" ref="container">
    <div class="aa" v-for="i in 100" :key="i">{{ i }}</div>
  </div>
  <div>
    <button @click="getImg">生成图片</button>
    <button @click="generateImage">下载图片</button>
  </div>
  <img :src="imgResult" alt="">
</template>

<script setup>
import html2canvas from "html2canvas"

import { ref } from 'vue'
const container = ref(null)
const imgResult = ref('')

// 生成图片
const getImg = async () => {
  const canvas = await html2canvas(container.value)
  const imgData = canvas.toDataURL()

  const img = new Image()
  img.onload = function () {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    const width = 100
    const height = 200

    canvas.width = width
    canvas.height = height
    ctx.drawImage(img, 0, 0, width, height)

    // 获取新的 data URL
    const newImgData = canvas.toDataURL()
    imgResult.value = newImgData
  }
  img.src = imgData
}

// 生成并下载
const generateImage = () => {
  const element = container.value // 获取需要生成图片的DOM元素
  html2canvas(element).then((canvas) => {

    let img = new Image() // 创建一个图片元素
    img.src = canvas.toDataURL('image/png')

    // document.body.appendChild(img) // 可以将img元素显示到DOM中

    // 直接下载图片
    let a = document.createElement('a')
    a.href = img.src
    a.download = 'vue3-img.png'
    a.click()
  })
}
</script>

<style scoped>
.container{
  width: 620px;
  background: #ccc;
}
.aa {
  color: red;
  border-bottom: 1px solid blueviolet;
}
</style>